.app__services{
    background-color: var(--color-black);
}

.app__services .headtext__cormorant{
    text-align: center;
}

.app__services-container {
    display: flex;
    flex-direction: column;
}