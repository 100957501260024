.app__serviceFeature {
    display: flex;
    margin: 4rem 4rem;
    padding: 4rem 4rem;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.app__serviceFeature-img img{
    height: 40vh;
    margin-left: 2rem;
}

.app__serviceFeature-feature .p__cormorant{
    font-size: 30px;
    margin-bottom: 0.5rem;
}

.app__serviceFeature-feature img{
    width: 5%;
}

.app__serviceFeature-feature-desc{
    margin: 1.5rem 0;
    
}

.app__serviceFeature-feature-desc-title{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.app__serviceFeature-feature-desc-title img{
    width: 10px;
    height: 10px;
    margin-right: 1rem;
}

.app__serviceFeature-feature-desc-title h1{
    color: var(--color-golden);
}

.app__serviceFeature-feature-desc p{
    font-size: 15px;
    margin: 0.1rem 3rem;
}

@media screen and (max-width: 1150px) {

    .app__serviceFeature {
        display: flex;
        margin: 4rem 4rem;
        padding: 2rem 2rem;
        margin-bottom: 0;
        flex-direction: column;
        align-items: center;
    }

    .app__serviceFeature-feature .p__cormorant{
        font-size: 20px;
    }

    .app__serviceFeature-feature-desc-title h1{
        font-size: 14px;
    }

    .app__serviceFeature-feature-desc p{
        font-size: 10px;
        line-height: 20px;
        margin: 0.1rem 3rem;
    }

    .app__serviceFeature-img img{
        height: 20vh;
        margin-left: 0rem;
    }
    
}

@media screen and (max-width: 650px) {
    .app__serviceFeature-feature .p__cormorant{
        font-size: 15px;
        margin-bottom: 0;
        line-height: 15px;
    }

    .app__serviceFeature-feature-desc-title h1{
        font-size: 12px;
    }

    .app__serviceFeature-feature-desc p{
        font-size: 8px;
        line-height: 15px;
        margin: 0.1rem 1.5rem;
    }

    .app__serviceFeature-img img{
        height: 20vh;
        margin-left: 0rem;
    }

    .app__serviceFeature-feature-desc-title img{
        width: 7px;
        height: 7px;
        margin-right: 0.5rem;
    }

}

@media screen and (max-width: 450px) {
    .app__serviceFeature {       
        margin: 2rem 2rem;
        padding: 1rem 1rem;
        margin-bottom: 0;
    }
}
