.App{
    width: 100vw;
    height: 80vh;
}

.app__header {
    background-color: var(--color-black);
}

.app__header-info{
    margin-left: 8rem;
    margin-right: 4rem;
}

.app__header-info h1{
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 40px;
    font-size: 80px;
    line-height: 80px;
}

.app__header-info p{
    margin-top: 2rem;
    width: 80%;
}

.app__header-info img {
    width : 10%;
}

.app__header-img {
    height: 70vh;
    display: flex;
    margin-left: 4rem;
    margin-right: 6rem;

}

.app__header-img img {
    height: 100%;
    width: auto;
    object-fit: contain;
}

@media screen and (min-width: 2000px) {
    .app__header-info h1 {
        font-size: 180px;
        line-height: 80px;
    }

    .app__header-img {
        height: 50vh;
        display: flex;
        margin: 4rem;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-info h1 {
        font-size: 70px;
        line-height: 80px;
    }

    .app__header-img {
        height: 50vh;
        display: flex;
        margin: 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__header-info h1 {
        font-size: 40px;
        line-height: 47px;
    }

    .app__header-img {
        height: 50vh;
        display: flex;
        margin: 4rem;
    }
}

@media screen and (max-width: 450px) {
    .app__header{
        margin: 0;
    }

    .app__header-info h1 {
        font-size: 30px;
        line-height: 30px;
    }

    .app__header-img {
        height: 30vh;
        display: flex;
        margin: 4rem;
    }
}