.app__footer {
    display: flex;
    background-color: var(--color-black);
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 5rem;
}


.app__footer-contact p{
    font-size: 14px;
    line-height: 20px;
}

.app__footer-contact .address {
    margin-top: 0.2rem;
}

.app__footer-logo {
    justify-content: center;
}

.app__footer-logo img{
    width: 16vw;
}


.app__footer-socialmedia img{
    width: 10vw;
}

@media screen and (max-width: 800px) {

    .app__footer {
        padding: 2rem 4rem;
    }

    .app__footer-contact p{
        font-size: 10px;
        font-weight: 30;
        line-height: 15px;
    }

}

@media screen and (max-width: 650px) {

    .app__footer {
        padding: 1.5rem 1rem;
    }

    .app__footer-contact p{
        font-size: 8px;
        font-weight: 30;
        line-height: 10px;
    }


}

@media screen and (max-width: 450px) {

    .app__footer {
        padding: 1.5rem 1rem;
    }

    .app__footer-contact p{
        font-size: 6px;
        font-weight: 30;
        line-height: 8px;
    }

}