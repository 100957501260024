.app__workFeature {
    padding: 4rem;
}

.app__workFeature h1{
    font-size: 30px;
    margin-bottom: 2rem;
}
.app__workFeature__img{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app__workFeature__img img{
    height: 45vh;
    padding: 1rem;
}

@media screen and (max-width: 1700px) {
    .app__workFeature__img img{
        height: 40vh;
        padding: 1rem;
    }
}


@media screen and (max-width: 1150px) {
    .app__workFeature h1{
        text-align: center;
    }

    .app__workFeature__img img{
        height: 45vh;
        padding: 1rem;
    }

    .app__workFeature__img-row1{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .app__workFeature__img-row2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 1150px) {
    .app__workFeature h1{
        font-size: 20px;
    }

}
