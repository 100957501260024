.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    height: 40vh;
    align-items: center;
    z-index: 0;
}

.app__aboutus-content {
    display: flex;
    gap: 6rem;
    width: 100%;
    margin: 10rem 8rem; 
    z-index: 2; 
}

.app__aboutus-content img{
    width: 10%;
}

.app__aboutus-content p{
    margin-top: 2rem;
}

.app__aboutus-content_about {
    text-align: right;
}

.app__aboutus-content_about img{
    transform: rotate(180deg);
}


@media screen and (max-width: 1150px) {
    .app__aboutus-content  {
        flex-direction: column;
        margin: 4rem 4rem; 
    }

    .app__aboutus-content h1 {
        font-size: 50px;
    }

    .app__aboutus-content_about {
        text-align: center;
    }

    .app__aboutus-content_history {
        text-align: center;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-content  {
        gap: 3rem; 
    }

    .app__aboutus-content h1{
        font-size: 30px;
    }

}

@media screen and (max-width: 450px) {
    .app__aboutus-content  {
        flex-direction: column;
        margin: 0;
    }

    .app__aboutus-content p{
        margin-top: 0;
    }
}

