.app__contact{
    background-color: var(--color-black);
    padding: 4rem;
    min-height: 80vh;
}

.app__contact-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 4rem;
}

.app__contact-title img{
    width: 5%;
}

.app__contact-number h1{
    font-size: 32px;
    margin: 4rem;
}

.app__contact-number p{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
}

.app__contact-number-heading p{
    color: var(--color-golden);
    font-style: italic;
    margin-bottom: 1rem;
}

.app__contact-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* Important for positioning the hover message */
  }
  
  .app__contact-map img {
    width: 60vw;
    transition: filter 0.3s ease; /* Smooth transition for blur */
  }
  
  .app__contact-map a {
    position: relative; /* Makes the hover message position absolute within the a tag */
    display: inline-block;
    text-decoration: none;
  }
  
  .app__contact-map a:hover img {
    filter: blur(1px);
  }
  
  .hover-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .app__contact-map a:hover .hover-message {
    opacity: 1; /* Show the message on hover */
  }
  

  @media screen and (max-width: 1150px) {

    
}

@media screen and (max-width: 650px) {
    .app__contact-title h1{
        font-size: 45px;
        line-height: 65px;
    }

    .app__contact-title img{
        width: 13%;
    }

    .app__contact-number h1{
        font-size: 15px;
        margin: 1rem 0;
    }

    .app__contact-number p{
        font-size: 15px;
        margin: 0;
    }

}

@media screen and (max-width: 450px) {
    .app__contact-title h1{
        font-size: 35px;
    }

    .app__contact-title img{
        width: 20%;
    }

    .app__contact-number h1{
        font-size: 15px;
        margin: 1rem 0;
    }

    .app__contact-number p{
        font-size: 8px;
        margin: 0;
    }
    
}