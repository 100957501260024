.app__founder {
    padding: 4rem;
}

.app__founder-img{
    height: 50vh;
    display: flex;
    margin-left: 8rem;
    margin-right: 6rem;
}

.app__founder-img img{
    height: 100%;
    width: auto;
    object-fit: contain;
}

.app__founder-info h1{
    font-size: 60px;
    line-height: 50px;
}

.app__founder-info p{
    font-size: 12px;
}

.app__founder-info__paragraph {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
}

.app__founder-info__paragraph img{
    height: 3.5vh;
    justify-content: flex-start;
    padding-right: 0.5rem;
}

.app__founder-info__paragraph P{
    margin-right: 4rem;
    font-size: 16px;
    font-style: italic;
}

@media screen and (max-width: 1150px) {
    .app__founder {
        flex-direction: column;
    }

    .app__founder-img{
        height: 30vh;
    }

    .app__founder-info h1{
        font-size: 50px;
        line-height: 50px;
        margin-top: 3rem;
    }

    .app__founder-info__paragraph P{
        margin-right: 0;
    }
}

@media screen and (max-width: 650px) {
    .app__founder{
        padding: 0;
    }

    .app__founder-img{
        height: 30vh;
    }

    .app__founder-info h1{
        font-size: 30px;
        line-height: 20px;
        margin-top: 3rem;
    }

    .app__founder-info p{
        font-size: 8px;
    }

    .app__founder-info__paragraph {
        margin-top: 0rem;
    }

    .app__founder-info__paragraph P{
        margin-top: 0;
        font-size: 12px;
    }

}